.flexContainer {
  display: flex;
  height: 90vh;  /* Ensure the full height of the viewport */
  width: 100vw;
  overflow: auto; /* Remove scroll bars */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.profileContainer {
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
  background-color: #F6F5F5;
  border-radius: 8px;
  padding: 20px;
  overflow: auto; /* Make this container scrollable */

}

.welcome-text {
  font-family: 'Aerial', cursive;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.text-gray-400 {
  color: #cbd5e0;
}

.text-blue-400 {
  color: #63b3ed;
}

.font-bold {
  font-weight: 700;
}

.bg-blue-600:hover {
  background-color: #2b6cb0;
}

.focus\:outline-none:focus {
  outline: none;
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
