.loginflexContainer {
  display: flex;
  height: 100vh;  
  width: 100vw;
  overflow: hidden; 
}



.halfWidth {
  width: 50%;
}

.hiddenMdBlock {
  display: none;
}

@media (min-width: 768px) {
  .hiddenMdBlock {
    display: block;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

/* Ensure the login form container uses full height on large screens */
.flexContainer > div {
  flex: 1; /* Ensures equal flex grow for both divs */
  height: 100%; /* Make sure both divs take full height */
}

.sliderContainer img {
  display: block;
  width: 100%;
  height: 100vh; /* Ensure image covers full viewport height */
  object-fit: cover;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2D3748;
  width: 100%;
}

.formContainer {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: #1A202C;
  border-radius: 8px;
  margin-bottom: 80px;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.text-gray-400 {
  color: #cbd5e0;
}

.text-blue-400 {
  color: #63b3ed;
}

.font-bold {
  font-weight: 700;
}

.bg-blue-600:hover {
  background-color: #2b6cb0;
}

.focus\:outline-none:focus {
  outline: none;
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.welcome-text {
  font-family: 'Pacifico', cursive;
}
